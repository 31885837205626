const data = {
  code: 0,
  data: {
    page: {
      total: 13,
      pageTotal: 10,
      pageNum: 1,
      pageSize: 10,
    },
    list: [
      {
        id: 593,
        historyId: 15,
        dateTime: 1679389772101,
        className: "班次2",
        mhi: "1.0",
        status: 0,
      },
      {
        id: 581,
        historyId: 14,
        dateTime: 1676262337178,
        className: "班次1",
        mhi: "2.0",
        status: 0,
      },
      {
        id: 569,
        historyId: 13,
        dateTime: 1675043831436,
        className: "班次1",
        mhi: "4.0",
        status: 0,
      },
      {
        id: 557,
        historyId: 12,
        dateTime: 1673322186429,
        className: "班次1",
        mhi: "5.0",
        status: 0,
      },
      {
        id: 545,
        historyId: 11,
        dateTime: 1661510746431,
        className: "班次2",
        mhi: "5.0",
        status: 0,
      },
      {
        id: 423,
        historyId: 8,
        dateTime: 1660805713771,
        className: "班次2",
        mhi: "4.4",
        status: 1,
      },
      {
        id: 183,
        historyId: 7,
        dateTime: 1660753163963,
        className: "班次1",
        mhi: "6.0",
        status: 1,
      },
      {
        id: 138,
        historyId: 6,
        dateTime: 1660746454430,
        className: "班次4",
        mhi: "2.0",
        status: 0,
      },
      {
        id: 123,
        historyId: 5,
        dateTime: 1660733764077,
        className: "班次3",
        mhi: "3.0",
        status: 0,
      },
      {
        id: 43,
        historyId: 4,
        dateTime: 1660719307374,
        className: "班次2",
        mhi: "4.0",
        status: 0,
      },
    ],
  },
};

export { data };
