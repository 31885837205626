const data = {
  "name": "getSeatStatDataRes",
  "data": {
    "classDateTime": 1660805704928,
    "className": "班次2",
    "diskId": 1,
    "status": 1,
    "mhi": "10.0",
    "milkSegments": [
      {
        "segmentId": 380,
        "beginDateTime": 1660806167000,
        "timeLen": 93,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 391,
        "beginDateTime": 1660806356000,
        "timeLen": 729,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 397,
        "beginDateTime": 1660807263000,
        "timeLen": 42,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 411,
        "beginDateTime": 1660807422000,
        "timeLen": 69,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 421,
        "beginDateTime": 1660807670000,
        "timeLen": 471,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 433,
        "beginDateTime": 1660808230000,
        "timeLen": 130,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 438,
        "beginDateTime": 1660808519000,
        "timeLen": 431,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 460,
        "beginDateTime": 1660808967000,
        "timeLen": 425,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 461,
        "beginDateTime": 1660809420000,
        "timeLen": 21,
        "status": 0,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 462,
        "beginDateTime": 1660809454000,
        "timeLen": 30,
        "status": 0,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 481,
        "beginDateTime": 1660809517000,
        "timeLen": 47,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 491,
        "beginDateTime": 1660809650000,
        "timeLen": 81,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 501,
        "beginDateTime": 1660814244000,
        "timeLen": 117,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 514,
        "beginDateTime": 1660814537000,
        "timeLen": 124,
        "status": 1,
        "diskId": 1,
        "seatId": 8
      },
      {
        "segmentId": 515,
        "beginDateTime": 1660814679000,
        "timeLen": 52,
        "status": 0,
        "diskId": 1,
        "seatId": 8
      }
    ]
  },
  "code": 0,
  "areaId": 0
}
export {data}