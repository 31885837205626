<template>
  <div class="shit">
    <div class="top">
      <div class="back" @click="back">
        <div class="back-arr">
          <div class="back-arr-top"></div>
          <div class="back-arr-bottom"></div>
        </div>
        <div class="back-txt">返回</div>
      </div>
      <div class="title">{{ title }}</div>
      <!-- <div class="change_bth btn" @click="hand" style="margin-right: 15px">
        手动测试
      </div> -->
      <div class="change_bth btn" @click="change">点位历史</div>
    </div>
    <div class="center">
      <div class="left">
        最终结果：

        <template v-if="info?.status == 1">
          <img class=" g_icon g_icon1" :src="require('./imgs/icon0.png')" />
          <span style="color: rgba(156, 245, 136, 1)">{{ statusText }}</span>
        </template>

        <template v-else-if="info?.status == 0">
          <img class="g_icon g_icon0" :src="require('./imgs/g_icon_0.png')" />
          <span style="color: #c1c1c1">{{ statusText }}</span>
        </template>
        <template v-else-if="info?.status == 2">
          <img class="g_icon g_icon2" :src="require('./imgs/g_icon_2.png')" />
          <span style="color: #ff3735">{{ statusText }}</span>
        </template>
        <template v-else-if="info?.status == 3">
          <img class="g_icon g_icon3" :src="require('./imgs/g_icon_3.png')" />
          <span style="color: #f2a91b">{{ statusText }}</span>
        </template>
      </div>
      <div class="line"></div>
      <div class="right">
        MHI：{{ Number(info?.mhi ?? 0).toFixed(1) }}
        <el-tooltip trigger="click" class="box-item" effect="dark">
          <template #content>
            <span style="font-size: 18px">MHI代表挤奶健康指数，最低0分，最高10分。分值越高，代表挤奶过程更加规范。</span>
          </template>
          <img class="b_icon" :src="require('./imgs/b_icon.png')" />
        </el-tooltip>
      </div>
    </div>
    <div class="bottom" id="bottom">
      <template v-if="chartList.length > 0">
        <!-- <div class="bottom_chart"> -->
          <div class="bottom-l">
            <div v-for="(item, index) in chartList" :key="index">
              {{ item.time }}
            </div>
          </div>
          <div class="bottom-c">
            <div v-for="(item, index) in chartList" :key="index">
              <span :class="{ no: index == 0 }" class="line"></span>
              <span class="dot"></span>
              <span :class="{ no: index == chartList.length - 1 }" class="line"></span>
            </div>
          </div>
          <div class="bottom-r">
            <div class="bar_box" v-for="(item, index) in chartList" :key="index">
              <div class="bar" :class="'bar_' + item.status" :style="{ width: item.percent }">
                <span>{{ item.timeLenStr }}</span>
                <span v-if="item.status != 0" @click="() => toDetail(item)">详情</span>
              </div>
            </div>
          </div>
        <!-- </div> -->

      </template>
      <template v-else>
        <div class="noData">暂无监测历史</div>
      </template>
    </div>

    <div class="footer">
      <div class="dot_item">
        <span class="dot dot_0"></span>
        <span class="text">无数据</span>
      </div>
      <div class="dot_item">
        <span class="dot dot_1"></span>
        <span class="text">正常</span>
      </div>
      <div class="dot_item">
        <span class="dot dot_2"></span>
        <span class="text">异常</span>
      </div>
    </div>
    <history-modal v-model:visible="historyVisible" :id="classId" />
  </div>
</template>
<script>
import {
  setRoot,
  msgArr,
  setSocketObj,
  socketObj,
  addBro,
  removeBro,
  sendOnly,
  send,
} from "@/socket/index.js";
import dayjs from "dayjs";
import HistoryModal from "./historyModal/index.vue";
import { data } from './data'

export default {
  name: "Shit",
  components: { HistoryModal },
  data() {
    return {
      info: {},
      max: 8,
      chartList: [],
      classId: "",
      historyVisible: false,

      initBroObj: {
        callbackName: "getSeatStatDataBro",
        callbackF: this.initBroCallbackF,
      },
    };
  },
  computed: {
    title() {
      if (!this.info?.classDateTime) return "";
      const date = dayjs(this.info.classDateTime).format("YYYY.MM.DD");
      return `${date}-${this.info.className}-${this.$route.query.diskId}#转盘第${this.$route.query.seatId}位-监测历史`;
    },
    statusText() {
      const map = {
        0: "未运行",
        1: "数据正常",
        2: "数据异常",
        3: "通信异常",
      };
      return map[this.info?.status ?? 0];
    },
  },
  watch: {
    "$route.query"(v, o) {
      this.refresh();
    },
  },
  mounted() {
    console.log(this.$route.query);
    // addBro(this.initBroObj);
    // sendOnly({
    //   name: "getSeatStatDataReq",
    //   data: {
    //     diskId: this.$route.query.diskId, //转盘id
    //     seatId: this.$route.query.seatId, //位置id
    //     historyId: this.$route.query.historyId,
    //     testStatId: this.$route.query?.testStatId,
    //   },
    // });

    this.refresh();
  },
  methods: {
    refresh() {
      // send({
      //   name: "getSeatStatDataReq",
      //   data: {
      //     diskId: this.$route.query.diskId, //转盘id
      //     seatId: this.$route.query.seatId, //位置id
      //     historyId: this.$route.query.historyId,
      //     testStatId: this.$route.query.testStatId,
      //   },
      // }).then((res) => {
      const res = data
      this.info = res.data;

      if (res?.data?.milkSegments?.length) {
        this.chartList = res.data.milkSegments.map((i) => {
          const percent = (i.timeLen * 100) / (8 * 60);
          return {
            time: dayjs(i.beginDateTime).format("HH:mm:ss"),
            percent: percent < 15 ? "15%" : percent + "%",
            testId: i.testId,
            seatId: i.seatId,
            diskId: i.diskId,
            status: i.status,
            segmentId: i.segmentId,
            timeLenStr: this.format(i.timeLen),
          };
        });
        this.scrollToBottom();
      } else {
        this.chartList = [];
      }
      // });
    },
    format(seconds) {
      const minutes = seconds / 60 >= 0 ? Math.floor(seconds / 60) : "";
      const s = seconds % 60;
      return minutes > 0 ? minutes + "分" + s + "秒" : s + "秒";
    },
    initBroCallbackF(res) {
      console.log(res, "initBroCallbackF");
      // console.log("initBroCallbackF");
      // res = res.data;
      const { diskId, seatId, historyId } = this.$route.query;

      if (historyId == 0 || historyId == res.data.historyId) {
        if (diskId == res.data.diskId && seatId == res.data.seatId) {
          this.info = res.data;

          if (res?.data?.milkSegments.length) {
            this.chartList = res.data.milkSegments.map((i) => {
              const percent = (i.timeLen * 100) / (8 * 60);
              return {
                time: dayjs(i.beginDateTime).format("HH:mm:ss"),
                percent: percent < 15 ? "15%" : percent + "%",
                testId: i.testId,
                seatId: i.seatId,
                diskId: i.diskId,
                status: i.status,
                segmentId: i.segmentId,
                timeLenStr: this.format(i.timeLen),
              };
            });
            this.scrollToBottom();
          } else {
            this.chartList = [];
          }
        }
      }
    },
    // async getInfo() {
    //   try {
    //     let res = await this.$myRequest({
    //       url: "/getSeatStatDataReq",
    //       method: "post",
    //       data: {
    //         diskId: this.$route.query.diskId, //转盘id
    //         seatId: this.$route.query.seatId, //位置id
    //         historyId: this.$route.query.historyId,
    //       },
    //     });
    //     res = res.data;
    //     this.info = res.data;

    //     this.chartList = res.data.milkSegments.map((i) => ({
    //       time: dayjs(i.beginDateTime).format("HH:mm"),
    //       percent: (i.timeLen * 100) / (8 * 60) + "%",
    //       testId: i.testId,
    //       seatId: i.seatId,
    //       diskId: i.diskId,
    //       status: i.status,
    //     }));
    //     console.log(this.chartList, " this.chartList");
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    back() {
      this.$router.go(-1);
    },
    change() {
      this.historyVisible = true;
    },
    async hand() {
      try {
        let res = await this.$myRequest({
          url: "/handTestReq",
          method: "post",
          data: {
            diskId: this.$route.query.diskId, //转盘id
            seatId: this.$route.query.seatId, //位置id
          },
        });
        res = res.data;
        if (res.code == 0) {
          this.$router.push({
            name: "ResultData",
            params: {
              diskId: this.$route.query.diskId, //转盘id
              seatId: this.$route.query.seatId, //位置id
              historyId: res.data.historyId,
            },
            query: {
              handHistoryId: res.data.historyId,
              // ...this.$route.query,
              handTest: this.$route.query.handTest
                ? parseInt(this.$route.query.handTest) + 1
                : 1,
            },
          });
        } else {
          if (res.msg) {
            ElMessage(res.msg);
          } else {
            ElMessage("系统出错");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    toDetail(item) {
      this.$router.push({
        name: "ResultHome",
        params: {
          diskId: item?.diskId ?? "1",
          seatId: item?.seatId ?? "1",
          historyId: this.$route.query.historyId,
        },
        query: {
          segmentId: item.segmentId,
        },
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#bottom");
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
