import { ElMessage } from "element-plus";
// import { loadingOption } from "../config/index";
export var socketObj;

export let msgArr = [];
window.msgArr = msgArr;
let root = null;
export function setRoot(obj) {
  root = obj;
}
export function setSocketObj(obj) {
  if (socketObj) {
    socketObj.onclose = null;
    socketObj.close();
  }
  socketObj = null;
  socketObj = obj;
  window.xsocketObj = obj;
  msgArr.forEach((v, k) => {
    if (v.loading) {
      v.loading.close();
    }
  });
  msgArr.splice(0);
}
export function addBro(callbackObj) {
  msgArr.push(callbackObj);
}
export function removeBro(callbackObj) {
  if (msgArr.indexOf(callbackObj) != -1) {
    msgArr.splice(msgArr.indexOf(callbackObj), 1);
  }
}
export function send(obj) {
  console.log("send this");
  // const loading = root.$loading(loadingOption);
  console.log("=============================");
  console.log("send");
  console.log(obj);
  console.log("=============================");

  let name = obj.name.slice(0, obj.name.lastIndexOf("Req"));
  socketObj.send(JSON.stringify(obj));


  return new Promise(function (resolve, reject) {
    // ... some code
    let callbackObj = {
      callbackName: name + "Res",
      loading,
      callbackF: function (res) {
        loading.close();
        // console.log('callback');
        // console.log(res);
        msgArr.splice(msgArr.indexOf(callbackObj), 1);
        callbackObj = null;
        if (res.code == 0) {
          resolve(res);
        } else {
          if (res.msg) {
            ElMessage(res.msg);
          } else {
            ElMessage("系统出错");
          }

          reject(res);
        }
      },
    };
    msgArr.push(callbackObj);
    setTimeout(() => {
      let curIndex = msgArr.indexOf(callbackObj);
      if (curIndex != -1) {
        callbackObj.callbackF({ code: -1, msg: "超时" });
        //重大bug，此处的删除多余了，回调里有删除逻辑
        // msgArr.splice(curIndex, 1);
      }
    }, 30000);
  });
}

export function sendOnly(obj) {
  console.log("=============================");
  console.log("sendOnly");
  console.log(obj);
  console.log("=============================");
  socketObj.send(JSON.stringify(obj));
}
