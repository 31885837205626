<template>
  <el-dialog v-model="visible" :class="'history-modal'" :before-close="handleClose" :title="title">
    <div class="history">
      <div class="history-con">

        <div>
          <el-radio-group v-model="type">
            <el-radio-button label="1">图表形式</el-radio-button>
            <el-radio-button label="2">数据形式</el-radio-button>
          </el-radio-group>
        </div>

        <div class="content_body">
          <el-table v-show="type == 2" border :data="tableData">
            <el-table-column prop="id" label="序号" width="100%">
            </el-table-column>
            <el-table-column prop="dateTime" label="日期" :formatter="formatterTime"></el-table-column>
            <el-table-column prop="className" width="100px" label="班次">
            </el-table-column>
            <el-table-column prop="status" width="150px" label="最终结果">
              <template #default="scope">
                <span :class="'text_' + scope.row.status">{{
                  statusMap[scope.row.status]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mhi" label="MHI" width="100">
            </el-table-column>

            <el-table-column label="操作" width="180%">
              <template #default="scope">
                <div class="detail-btn" @click="look(scope.$index, scope.row)">
                  查看详情
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="chart" v-show="type == 1">
            <div id="chart" class="chart"></div>
          </div>
        </div>
        <!-- <el-pagination v-if="pageRes.total > 10" background layout="prev, pager, next" :total="pageRes.total" class="page"
          v-model:currentPage="page.pageNum" @current-change="handleCurrentChange" :page-size="page.pageSize">
        </el-pagination> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import { data } from './data'

export default {
  props: {
    visible: Boolean,
    id: String,
  },
  data() {
    return {
      type: '1',
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      pageRes: {
        total: 200,
      },
      statusMap: {
        0: "未运行",
        1: "数据正常",
        2: "数据异常",
        3: "通信异常",
      },
    };
  },
  computed: {
    title() {
      return `${this.$route.query.diskId}#转盘第${this.$route.query.seatId}位`;
    },
  },
  watch: {
    visible: async function (nV, oV) {
      if (nV) {
        console.log("打开弹窗-班次历史");
        await this.getList();
        this.setChart();

      } else {
        console.log("关闭弹窗-班次历史");
      }
    },
  },
  methods: {
    async getList() {
      // try {
      //   let res = await this.$myRequest({
      //     url: "/getSeatHistoryListReq",
      //     method: "post",
      //     data: {
      //       diskId: this.$route.query.diskId, //转盘id
      //       seatId: this.$route.query.seatId, //位置id
      //       page: this.page,
      //     },
      //   });
      //   res = res.data;
      console.log(data);
      let res = data
      this.pageRes = res.data.page;
      this.tableData = res.data.list;
      // } catch (err) {
      //   console.log(err);
      // }
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    look(index, row) {
      this.handleClose();
      this.$router.push({
        name: "Shit",
        query: {
          diskId: this.$route.query.diskId,
          seatId: this.$route.query.seatId,
          testStatId: row.id,
          historyId: this.$route.query.historyId
            ? this.$route.query.historyId
            : 0,
        },
      });
    },
    formatterMode(row, column) {
      let option = { 0: "手动", 1: "自动" };

      return option[row.mode];
    },
    formatterTarget(row, column) {
      if (row.mode == 0) {
        return `${row.diskId}号转盘${row.seatId}号位置`;
      } else {
        return "全部";
      }
    },
    formatterTime(row, column) {
      return moment(row.dateTime).format("YYYY.MM.DD HH:mm:ss");
    },
    handleCurrentChange() {
      this.getList();
    },
    setChart(data) {
      // let data = [
      //   {
      //     name: "上限",
      //     time: ["00:00", "00:01", "00:02", "00:03"],
      //     value: [1, 2, 3, 4],
      //   },
      // ];
      this.chart && this.chart.dispose();

      let dom = document.getElementById("chart");
      let chart = echarts.init(dom);
      let option;

      console.log(this.tableData, 'll');

      const timeData = this.tableData.map(i => moment(i.dateTime).format("YYYY.MM.DD"))
      const data1 = this.tableData.map(i => parseFloat(i.mhi))
      const data2 = this.tableData.map(i => 3)

      console.log(timeData);
      option = {
        title: {
          text: '班次图表',
          textStyle: {
            color: "#fff",
          }
        },
   
        // color: ['#376247', '#fd0c00'],
        legend: {
          textStyle: {
            color: "#fff",
          },
          data: ['MHI', '报警值']
        },
        grid: {
          left: 20,
          bottom: 40,
          right: 40,
          top: 50,
          containLabel: true
        },
        tooltip: {
          confine: true,
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
          data: timeData
        },
        yAxis: [
          {
            scale: true,
            nameLocation: 'end',
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff",
              },
            },
            type: "value",
          },
        ],

        series: [
          {
            name: 'MHI',
            type: 'line',
            data: data1,
            symbol: 'circle',
            symbolSize: '10',
            markLine: {
              lineStyle: {
                color: "#fd0c00",
              },
              data: [
                {
                  name: "报警值",
                  yAxis: 3,
                },
              ],
              label: {
                normal: {
                  formatter: "报警值",
                  color: '#fff'
                }
              }
            },
          },

          // {
          //   name: '报警值',
          //   type: 'line',
          //   data: data2,
          //   lineStyle: {
          //     type: 'dotted',
          //   }
          // },

        ]
      };
      chart.setOption(option);
      this.chart = chart;
    },
  },
  mounted() {

  },
  beforeUnmount() {
    this.chart && this.chart.dispose();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
